import Aperture from 'assets/images/home/Aperture.png';
import NPO from 'assets/images/home/NPO.png';
import POMP from 'assets/images/home/POMP.png';

import icon5 from 'assets/images/home/icon5.svg';
// import icon6 from 'assets/images/home/icon6.svg';
import icon7 from 'assets/images/home/icon7.svg';
// import icon8 from 'assets/images/home/icon8.svg';
// import icon9 from 'assets/images/home/icon9.svg';
import zkHoldem from 'assets/images/home/zkHoldem.png';

import adam from 'assets/images/team/adam.svg';
import algod from 'assets/images/team/algod.svg';
import boyuan from 'assets/images/team/boyuan.svg';
import brandon from 'assets/images/team/brandon.svg';
import chloe from 'assets/images/team/chloe.svg';
import da from 'assets/images/team/da.svg';
import danny from 'assets/images/team/danny.svg';
import dylan from 'assets/images/team/dylan.svg';
import fran from 'assets/images/team/fran.svg';
import georgi from 'assets/images/team/georgi.svg';
import godot from 'assets/images/team/godot.svg';
import helena from 'assets/images/team/helena.svg';
import holly from 'assets/images/team/holly.svg';
import jamie from 'assets/images/team/jamie.svg';
import jenni from 'assets/images/team/jenni.svg';
import kate from 'assets/images/team/kate.svg';
import kenny from 'assets/images/team/kenny.svg';
import kevin from 'assets/images/team/kevin.svg';
import luke from 'assets/images/team/luke.svg';
import nikola from 'assets/images/team/nikola.svg';
import pierre from 'assets/images/team/pierre.svg';
import rob from 'assets/images/team/rob.svg';
import ruihan from 'assets/images/team/ruihan.svg';
import shumo from 'assets/images/team/shumo.svg';
import simeon from 'assets/images/team/simeon.svg';
import todd from 'assets/images/team/todd.svg';
import toghrul from 'assets/images/team/toghrul.svg';
import vankyo from 'assets/images/team/vankyo.svg';
import victor from 'assets/images/team/victor.svg';
import wei from 'assets/images/team/wei.svg';
import yi from 'assets/images/team/yi.svg';

export const contributors = [
  {
    avatar: adam,
    name: 'Adam Reif',
    job: 'Runtime',
    github: 'https://github.com/Garandor',
    twitter: '',
  },
  {
    avatar: boyuan,
    name: 'Boyuan',
    job: 'Cryptography',
    github: 'https://github.com/BoyuanFeng',
    twitter: '',
  },
  {
    avatar: brandon,
    name: 'Brandon',
    job: 'Cryptography',
    github: 'https://github.com/bhgomes',
    twitter: 'https://twitter.com/brandonhgomes',
  },
  {
    avatar: chloe,
    name: 'Chloe',
    job: 'Product',
    github: '',
    twitter: 'https://twitter.com/0xchloey',
  },
  {
    avatar: danny,
    name: 'Danny',
    job: 'Full-Stack',
    github: 'https://github.com/dansofter',
    twitter: '',
  },
  {
    avatar: dylan,
    name: 'Dylan Dedi',
    job: 'Marketing',
    github: '',
    twitter: 'https://twitter.com/dylan_dedi',
  },
  {
    avatar: fran,
    name: 'Francisco',
    job: 'Cryptography',
    github: 'https://github.com/SupremoUGH',
    twitter: '',
  },
  {
    avatar: georgi,
    name: 'Georgi',
    job: 'Runtime',
    github: 'https://github.com/ghzlatarev',
    twitter: '',
  },
  {
    avatar: godot,
    name: 'Godot Sancho',
    job: 'Marketing',
    github: '',
    twitter: 'https://twitter.com/GodotSancho',
  },
  {
    avatar: helena,
    name: 'Helena',
    job: 'Marketing',
    github: '',
    twitter: 'https://twitter.com/helsfoftroy',
  },
  { avatar: holly, name: 'Holly', job: 'Marketing', github: '', twitter: '' },
  {
    avatar: jamie,
    name: 'Jamie',
    job: 'Runtime',
    github: 'https://github.com/Dengjianping',
    twitter: '',
  },
  { avatar: jenni, name: 'Jennifer', job: 'Marketing', github: '', twitter: '' },
  { avatar: kate, name: 'Kate Milburn', job: 'Operations', github: '', twitter: '' },
  {
    avatar: kevin,
    name: 'Kevin Gislason',
    job: 'Full-Stack',
    github: 'https://github.com/Kevingislason',
    twitter: '',
  },
  {
    avatar: nikola,
    name: 'Nikola Dimitroff',
    job: 'Runtime',
    github: 'https://github.com/nikoladimitroff',
    twitter: '',
  },
  { avatar: pierre, name: 'Pierre', job: 'Cryptography', github: '', twitter: '' },
  { avatar: ruihan, name: 'Ruihan Wang', job: 'BizDev', github: '', twitter: '' },
  { avatar: rob, name: 'Rob', job: 'DevOps', github: 'https://github.com/grenade', twitter: '' },
  { avatar: simeon, name: 'Simeon', job: 'Runtime', github: '', twitter: '' },
  {
    avatar: todd,
    name: 'Todd',
    job: 'Cryptography',
    github: 'https://github.com/GhostOfGauss',
    twitter: '',
  },
  { avatar: vankyo, name: 'Vankyo', job: 'BizDev', github: '', twitter: '' },
  { avatar: yi, name: 'Yi Liu', job: 'Runtime', github: 'https://github.com/flame4', twitter: '' },
];

export const advisors = [
  {
    avatar: algod,
    name: 'Algod',
    job: 'Capital78',
    github: '',
    twitter: 'https://twitter.com/AlgodTrading',
  },
  {
    avatar: da,
    name: 'Da-Bing',
    job: 'Consensys',
    github: '',
    twitter: 'https://twitter.com/hotpot_dao',
  },
  {
    avatar: luke,
    name: 'Luke Pearson',
    job: 'Polychain Capital',
    github: '',
    twitter: 'https://twitter.com/lukejdpearson',
  },
  {
    avatar: toghrul,
    name: 'Toghrul Maharramov',
    job: 'Scroll Tech',
    github: '',
    twitter: 'https://twitter.com/toghrulmaharram',
  },
  {
    avatar: wei,
    name: 'Wei Dai',
    job: 'Bain Capital Crypto',
    github: '',
    twitter: 'https://twitter.com/_weidai',
  },
];

export const founders = [
  {
    avatar: shumo,
    name: 'Shumo Chu',
    job: 'Technical',
    twitter: 'https://twitter.com/shumochu',
    intro:
      'Shumo obtained a Ph.D. In Computer Science and Engineering from University of Washington Before UCSB, he served sa a research scientist in Algorand. He published over 10 peer-reviewed papers in top conferences.',
  },
  {
    avatar: victor,
    name: 'Victor Li',
    job: 'Strategy',
    twitter: 'https://twitter.com/victorJi15',
    intro:
      'Victor received his Masters in economics from Harvard University. He previously served as the Chair of Harvard Kennedy School Blockchain PIC. Before Harvard, he was the executive partner of BitBlock Capital and venture partner of PrimeBlock Ventures.',
  },
  {
    avatar: kenny,
    name: 'Kenny Li',
    job: 'Operations',
    twitter: 'https://twitter.com/superanonymousk',
    intro:
      'Kenny received his MBA from MIT. He is an entrepreneur that has started, advised, and invested in startups for over a decade, He previously has done work for MIT’s DCI and served as teaching assistant for blockchain courses at MIT.',
  },
];

export const projects = [
  {
    iconLeft: icon5,
    // iconRight: icon6,
    title: 'Manta Pacific',
    subtitle: 'Live',
    intro:
      'Manta Pacific is the modular L2 ecosystem for EVM-native ZK applications and dapps that want to deliver the lowest cost and best experience for users. Leveraging Manta’s Universal Circuits to enable ZK-as-a-Service, and Celestia’s data availability for modularity to deliver low gas fees, Manta Pacific offers the perfect environment for ZK-enabled applications. ',
    btnText: 'Enter Manta Pacific',
    link: 'https://pacific.manta.network/',
  },
  {
    iconLeft: icon7,
    // iconRight: icon8,
    title: 'Calamari Network',
    subtitle: 'Live',
    intro:
      'Calamari, Manta Network’s canary-net, is the plug-and-play privacy-preservation parachain built to service the Kusama ecosystem',
    btnText: 'Enter Calamari',
    link: 'https://calamari.network/',
  },
];

export const useCase = [
  {
    icon: Aperture,
    title: 'Aperture',
    intro: 'The pioneering LP management and Swap on Manta Pacific.',
    link: 'https://app.aperture.finance/?chainId=1',
  },
  {
    icon: zkHoldem,
    title: 'zkHoldem',
    intro: "On-chain Texas Hold'em, powered by ZKP and HomomorphicEncryption.",
    link: 'https://zkholdem.xyz/',
  },
  {
    icon: POMP,
    title: 'POMP',
    intro:
      'Verify your on-chain activity and assets while keeping wallet address and sensitive information private.',
    link: 'https://pomp.money/mint',
  },
  {
    icon: NPO,
    title: 'NPO',
    intro:
      'Launchpad that mints zkNFTs/zkSBTs on zkAddress while using public tokens to pay minting fees.',
    link: 'https://npo-evm.manta.network/evm/sbt',
  },
];
